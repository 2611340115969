/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('prism-themes/themes/prism-atom-dark.css');

exports.onInitialClientRender = () => {
  /*
  The 'typeface' was deprecated and the recommended way is to use
  '@fontsource'. However...

  1. There is no Droid Sans on fontsource
  2. The Open Sans Condensed is *different*

  On top of that, the 1.x release of typeface-droid-sans is bugged
  and does NOT include the font files... so, don't even bother
  updating it...
  */
  require("typeface-droid-sans");
  require("typeface-open-sans-condensed")
  require("@fontsource/press-start-2p");
};
